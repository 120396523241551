<template>
  <div class="flex flex-col justify-center align-center items-centers space-y-8 pt-24 sm:pt-48">
    <p class="text-2xl font-bold">Coming Soon</p>
    <img alt="Construction Logo" class="w-[65px] h-[65px] mx-auto" src="../assets/crane.png">
    <p class="text-lg">Aerocle Labs is currently under construction.</p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
}
</script>

